

import { Component, Prop, Ref, Vue } from 'nuxt-property-decorator'
import IconCopy from '~/assets/icons/icon-copy-clipboard.svg?inline'
import IconCheck from '~/assets/icons/icon-check.svg?inline'
import AppTransition from '~/components/AppTransition'

@Component({
  components: {
    AppTransition,
    IconCopy,
    IconCheck,
  },
})
export default class CopyVoucherInput extends Vue {
  @Ref('voucherCodeInput')
  readonly voucherCodeInput!: HTMLInputElement

  @Prop({ type: String, required: false, default: '' })
  readonly label!: string

  @Prop({ type: String, required: true })
  readonly voucherCode!: string

  isCopyDone = false
  copyTimeout: ReturnType<typeof setTimeout> | null = null

  copyCode () {
    this.voucherCodeInput.select()

    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(this.voucherCode)
    } else {
      document.execCommand('copy')
    }

    this.setCopyDone()
  }

  setCopyDone () {
    this.isCopyDone = true

    this.copyTimeout = setTimeout(() => {
      this.isCopyDone = false
    }, 5000)
  }

  beforeDestroy () {
    if (this.copyTimeout) {
      clearTimeout(this.copyTimeout)
    }
  }
}
