
import { Component, Emit, Vue } from 'nuxt-property-decorator'
import { getCookie, setCookie } from '@alao-frontend/utils'
import AppModal from '~/components/AppModal/AppModal.vue'
import { OnMounted } from '~/core/vue.types'
import CopyVoucherInput from '~/components/ExitPopupWithVoucher/CopyVoucherInput.vue'

@Component({
  components: {
    CopyVoucherInput,
    AppModal,
  },
})
export default class ExitPopupWithVoucher extends Vue implements OnMounted {
  show = false
  promoCode = 'SUMMER24'
  amount = 30

  get badgeUrl () {
    return require('~/assets/images/badges/exit-modal-badge.svg')
  }

  handleCTAClick () {
    this.$router.push(this.localePath({ name: 'compare-mobile' }))
    this.$nextTick(() => {
      this.onModalClose(2)
    })
  }

  @Emit('on-cancel')
  onModalClose (days: number) {
    this.show = false
    const date = new Date()
    setCookie('isExitBannerHidden', 1, {
      expires: new Date(date.setDate(date.getDate() + days)),
      path: '/',
    })
    document.removeEventListener('mouseleave', this.showModal)
  }

  sendGTMEvent <T extends Record<string, unknown>>(
    eventName: 'popup_shown',
    data: T,
  ) {
    this.$analytics.gtm.trackCustomEvent('GTMevent-v2', {
      eventName,
      data,
      _clear: true,
    })
  }

  showModal (event: MouseEvent) {
    if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
      this.show = true

      this.sendGTMEvent('popup_shown', {
        event_category: 'popup',
        event_action: 'shown',
        event_label: 'left_sitebox',
        trigger_action: 'left_sitebox',
        language_code: this.$i18n.locale.toUpperCase(),
      })
    }
  }

  mounted () {
    if (process.client && !getCookie('isExitBannerHidden')) {
      document.addEventListener('mouseleave', this.showModal, { once: true })
    }
  }
}
