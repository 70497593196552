var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.rating > 0 && _vm.reviews > 0)?_c('div',{class:[
    'google-rating',
    `google-rating--size_${_vm.size}`,
    `google-rating--theme_${_vm.theme}`,
  ]},[_c('div',{staticClass:"google-rating__icon"},[_c('IconGoogle',_vm._b({},'IconGoogle',{
        width: _vm.iconSize,
        height: _vm.iconSize,
      },false))],1),_vm._v(" "),_c('div',{staticClass:"google-rating__content"},[_c('div',{staticClass:"google-rating__amount"},[_c('strong',[_vm._v(_vm._s(_vm.rating))]),_vm._v(" "),_vm._l((_vm.ratingRounded),function(star){return _c('i',{key:star,staticClass:"icon-star icon-star--active"})})],2),_vm._v(" "),(_vm.isLoaded && !_vm.isLoading)?_c('small',[_vm._v("\n      "+_vm._s(_vm.$t("widgets.googleRatingLite.basedOn", { reviews: _vm.reviews }))+"\n    ")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }