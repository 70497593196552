var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"copy-voucher"},[(_vm.label)?_c('label',{staticClass:"copy-voucher__label",attrs:{"for":"exit_intent_voucher_code"},domProps:{"innerHTML":_vm._s(_vm.label)}}):_vm._e(),_vm._v(" "),_c('div',{class:[
      'copy-voucher__wrapper',
      { 'copy-voucher__wrapper--done': _vm.isCopyDone }
    ]},[_c('input',{ref:"voucherCodeInput",staticClass:"copy-voucher__input",attrs:{"id":"exit_intent_voucher_code","type":"text","readonly":""},domProps:{"value":_vm.voucherCode}}),_vm._v(" "),_c('button',{class:[
        'copy-voucher__button',
        { 'copy-voucher__button--done': _vm.isCopyDone }
      ],attrs:{"type":"button"},on:{"click":function($event){return _vm.copyCode()}}},[_c('AppTransition',{attrs:{"name":"fade"}},[(_vm.isCopyDone)?_c('IconCheck',{staticClass:"copy-voucher__icon copy-voucher__icon--done"}):_c('IconCopy',{staticClass:"copy-voucher__icon copy-voucher__icon--copy"})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }